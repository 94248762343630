/**
 * Componente responsável pelo comportamento do form da barra de filtro.
 */

import { DomHelper } from '../utils/dom-helper'
import { EventKeyHelper } from '../utils/event-key-helper'
import { UrlHelper } from '../utils/url-helper'

export class FilterBarFormHandler {

  /* consts */

  static INPUTS_SELECTOR = 'input:not([data-filter-bar-bypass-clear]):not([type=submit])'
  static SELECTS_SELECTOR = 'select:not([data-filter-bar-bypass-clear])'

  constructor(aFilterBarContainer, aFilterBar) {

    /* globals */

    this._domFilterBarContainer = aFilterBarContainer
    this._filterBar = aFilterBar
    this._domHelper = new DomHelper(this._domFilterBarContainer)
    this._domFilterForm = aFilterBarContainer.querySelector('form')
    this._urlHelper = new UrlHelper()
    this._eventKeyHelper = new EventKeyHelper()
    this._isClearingFilters = false

    this._domFilterForm._filterBarFormHandler = this
  }

  /* public API */

  clearFilter() {
    this._clearFilter()
  }

  isClearingFilters() {
    return this._isClearingFilters
  }

  submitForm() {
    this._submitForm()
  }

  handleKeyUp(aEvent) {
    this._handleKeyUp(aEvent)
  }

  updateUrl() {
    this._updateUrl()
  }

  getFormParameters() {
    return this._getFormParameters()
  }

  /* privates */

  _clearFilter() {
    this._isClearingFilters = true
    this._resetForm()
    this._isClearingFilters = false

    this._submitForm()
  }

  _resetForm() {
    var inputs = this._domFilterForm.querySelectorAll(FilterBarFormHandler.INPUTS_SELECTOR),
        selects = this._domFilterForm.querySelectorAll(FilterBarFormHandler.SELECTS_SELECTOR)

    this._clearInputs(inputs)
    this._clearSelects(selects)
  }

  _submitForm() {
    // atualiza a url na barra pois o load é via ajax...
    this._updateUrl()

    Rails.fire(this._domFilterForm, 'submit')
  }

  _getFormParameters() {
    var validInputs = this._domFilterForm.querySelectorAll('input, select'),
        formParams = this._getSerializedArrayFromInputs(validInputs),
        urlParams = this._urlHelper.getQueryParameters(),
        params =  this._urlHelper.mergedParams(urlParams, formParams)

    // temos que inserir um outro parâmetros pois a url será alterada (History
    // API) e alguns navegadores irão cachear o resultado do AJAX pela sua url.
    // Quando a API encontrar o cache com a mesma url, irá renderizar o json
    // resultado do AJAX e não a página original.
    if (!this._urlHelper.paramExists(params, '__')) {
      params.push({ name: '__', value: '__' })
    }

    return params
  }

  _getSerializedArrayFromInputs(aInputs) {
    const result = Array.from(aInputs).map((aInput) => {
      const ignore = aInput.getAttribute('data-ignore-on-filter-bar-url-update') === 'true'

      if (! ignore) {
        if (aInput.type === 'checkbox') {
          return { name: aInput.name, value: (aInput.checked ? 'true' : '') }
        } else if (aInput.multiple) {
          return { name: aInput.name, value: $(aInput).val()?.join(',') }
        } else {
          return { name: aInput.name, value: aInput.value }
        }
      }
    })

    return Array.from(result).filter((aItem) => { return (!! aItem) })
  }

  /*
   * Restaura os valores dos filtros baseado na URL. Usado no popstate do
   * histórico.
   */
  _restoreFormParameters(aParams) {
    var params = aParams,
        inputs = this._domFilterForm.querySelectorAll(':input')

    for (var i = 0; i < inputs.length; i++) {
      var input = inputs[i],
          name = input.name

      if (aParams[name] !== undefined) {
        var paramValue = aParams[name],
            paramValueWithSpaces = (paramValue + '').replace(/\+/g, '%20'),
            decodedValue = decodeURIComponent(paramValueWithSpaces)

        if (input.type === 'checkbox') {
          input.checked = (decodedValue === input.value)
        } else {
          input.value = decodedValue
        }
      }
    }
  }

  _handleKeyUp(aEvent) {
    var event = aEvent,
        keyCode = event.keyCode,
        input = event.target

    if (this._eventKeyHelper.isEnterKeyCode(keyCode)) {
      if (input.getAttribute('data-filter-bar-ignore-submit-on-enter') === 'true') {
        // para campos de busca dentro de form e que possuem botão de submit,
        // temos que ignorar o enter pois o próprio rails faz a submissão e iria
        // 2 vezes.

        this._updateUrl()

        return
      }

      this._submitForm()
    }

    if (this._eventKeyHelper.isEscKeyCode(keyCode)) {
      this._clearInputs([input])
      this._submitForm()
    }

    aEvent.preventDefault()
    aEvent.stopPropagation()
  }

  _clearInputs(aInputs) {
    Array.from(aInputs).forEach((aInput) => {
      if (aInput.type === 'checkbox') {
        aInput.checked = false
      } else {
        aInput.value = null
      }
    })
  }

  _clearSelects(aSelects) {
    Array.from(aSelects).forEach((aSelect) => {
      if (aSelect.getAttribute('data-select2-id') !== null) {
        const select2 = $(aSelect)
         // jeito select2 para limpar...
        select2.val(null).trigger('change');
        select2.find('option').prop('selected', false);
      } else {
        const selected = aSelect.selectedOptions

        if (selected) {
          for (let i=0; i < selected.length; i++) {
            selected[i].selected = false
          }
        }
      }
    })
  }

  _updateUrl() {
    var filterBarId = this._filterBar.getFilterBarId(),
        stateData = { filter_bar_id: filterBarId, reload: true } /* sinaliza um reload para o popstate para backbutton funcionar */

    this._urlHelper.updateUrlParams(
      this._getFormParameters(),
      true, /* aPushState (false para não adicionar nova entrada no histórico) */
      stateData
    )
  }
}
