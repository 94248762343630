/**
 * Componente utilitário para tratamento de teclas.
 */

export class EventKeyHelper {

  static KEY_CODES = {
    'tab': 9,
    'enter': 13,
    'esc': 27,
    'key_up': 38,
    'key_down': 40
  }

  // /* globals */

  // const self = this;

  /* API */

  keyCode(aEvent) {
    return this._keyCode(aEvent);
  }

  isCharKeyCode(aKeyCode) {
    return this._isCharKeyCode(aKeyCode);
  }

  isEnterKeyCode(aKeyCode) {
    return (aKeyCode === EventKeyHelper.KEY_CODES.enter);
  }

  isEscKeyCode(aKeyCode) {
    return (aKeyCode === EventKeyHelper.KEY_CODES.esc);
  }

  isTabKeyCode(aKeyCode) {
    return (aKeyCode === EventKeyHelper.KEY_CODES.tab);
  }

  isKeyUpKeyCode(aKeyCode) {
    return this._isKeyUpKeyCode(aKeyCode);
  }


  isKeyDownKeyCode(aKeyCode) {
    return this._isKeyDownKeyCode(aKeyCode);
  }

  isDirectionalKeyCode(aKeyCode) {
    return (this._isDirectionalKeyCode(aKeyCode));
  }

  /* private */

  /*
   * Retorna o código da tecla de determinado evento (cross-browser).
   */
  _keyCode(aEvent) {
    return (aEvent.keyCode ? aEvent.keyCode : aEvent.which);
  }

  /*
   * Retorna se a tecla pressionada é alguma das direcionais
   */
  _isDirectionalKeyCode(aKeyCode) {
    return (_isKeyDownKeyCode(aKeyCode) || _isKeyUpKeyCode(aKeyCode));
  }

  _isKeyDownKeyCode(aKeyCode) {
    return (aKeyCode === EventKeyHelper.KEY_CODES.key_down);
  }

  _isKeyUpKeyCode(aKeyCode) {
    return (aKeyCode === EventKeyHelper.KEY_CODES.key_up);
  }

  /*
   * Retorna se a tecla pressionada corresponde a algum caracter visívei
   */
  _isCharKeyCode(aKeyCode) {
    return (aKeyCode >= 65 && aKeyCode <= 90);
  }
}
