/*
 * Módulo para carregamento automatico dos RemoteContentWithFilterBar.
 *
 * Utiliza os seguintes parâmetros:
 *
 *   <... data-toggle='remote-content-with-filter-bar'>
 *
 *      <... 'data-remote-content'...
 *
 *         <... 'data-filter-bar'...
 *
 *         <... 'data-remote-content-result'...
 *   [data-content=loading]: Loader para exibir durante a requisição ajax
 */

import { FilterBar } from '../components/filter-bar/filter-bar'
import { RemoteContent } from '../components/remote-content/remote-content'

document.addEventListener('turbo:load', (aEvent) => {
  const items = document.querySelectorAll('[data-toggle=remote-content-with-filter-bar]')

  Array.from(items).forEach((aItem) => {

    const mainContainer = aItem,
          remoteContentContainer = mainContainer.querySelector('[data-remote-content]'),
          filterBarContainer = mainContainer.querySelector('[data-filter-bar]'),
          filterBar = new FilterBar(filterBarContainer),
          remoteContent = new RemoteContent(remoteContentContainer, filterBar)

  })

})
