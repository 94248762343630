import 'bootstrap-datepicker/dist/js/bootstrap-datepicker';
import 'inputmask';

const $ = require("jquery");
// // The current card.js code does not explicitly require jQuery, but instead uses the global, so this line is needed.
// window.jQuery = $;
const card = require("card");

/**
 * JavaScript central para tratamento das máscaras
 */

export class MaskManager {

  // public

  /*
   * Atualiza as máscaras para os campos que estiverem dentro de aContainer.
   * Caso aContainer seja null, será considerado $(document).
   * aContainer é usado para nested inseridos depois do load.
   */

  initMasks(aContainer) {
    const container = $(aContainer)

    this._updateCpfMasks(container);
    this._updateCnpjMasks(container);
    this._updateIntegerMasks(container);
    this._updateMoneyMasks(container);
    this._updateMoneyPositiveMasks(container);
    this._updatePercentageMasks(container);
    this._updateYearMasks(container);
    this._updatePhoneMasks(container);
    this._updateZipcodeMasks(container);
    this._updateCreditCardMasks(container);
    this._updateElectorDocumentMasks(container);
    this._updateCustomMasks(container);
    this._initDatePicker(container);
  }

  /// masks / plugins

  _initDatePicker(aContainer) {
    $.fn.datepicker.dates['pt-BR'] = {
      days: ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"],
      daysShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
      daysMin: ["Do", "Se", "Te", "Qu", "Qu", "Se", "Sa"],
      months: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
      monthsShort: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
      today: "Hoje",
      monthsTitle: "Meses",
      clear: "Limpar",
      format: "dd/mm/yyyy"
    }

    $(aContainer).find('[data-mask=date]').datepicker({
      language: 'pt-BR'
    })
  }

  _updateCpfMasks(aContainer) {
    this._updateMasks(aContainer.find('[data-mask=cpf]'), '999.999.999-99');
  }

  _updateCnpjMasks(aContainer) {
    this._updateMasks(aContainer.find('[data-mask=cnpj]'), '99.999.999/9999-99');
  }

  _updateIntegerMasks(aContainer) {
    aContainer.find('[data-mask=integer]').each((aIndex, aItem) => {

      // inteiros > 0
      this._setInputFilter(aItem, function(aValue) {
        const isIntegerValue = /^\d*$/.test(aValue),
              minValue = aItem.getAttribute('data-mask-min'),
              maxValue = aItem.getAttribute('data-mask-max'),
              validMinValue = (minValue === null || +(minValue) <= +(aValue)),
              validMaxValue = (maxValue === null || +(maxValue) >= +(aValue))

        return (isIntegerValue && validMinValue && validMaxValue)
      })

    })
  }

  _setInputFilter(aItem, aInputFilter) {

    if (aItem) {
      ["input", "keydown", "keyup", "mousedown", "mouseup", "select", "contextmenu", "drop"].forEach((aEventType) => {

        aItem.addEventListener(aEventType, (aEvent) => {

          if (aInputFilter(aItem.value)) {

            aItem.oldValue = aItem.value
            aItem.oldSelectionStart = aItem.selectionStart
            aItem.oldSelectionEnd = aItem.selectionEnd

          } else if (aItem.hasOwnProperty("oldValue")) {

            aItem.value = aItem.oldValue
            aItem.setSelectionRange(aItem.oldSelectionStart, aItem.oldSelectionEnd)

          } else {

            aItem.value = ""

          }
        })

      })
    }
  }

  _updateMoneyMasks(aContainer) {
    this._updateMasks(aContainer.find('[data-mask=money]'), "currency", this._getDefaultNumberOptions())
  }

  _updateMoneyPositiveMasks(aContainer) {
    var options = this._getDefaultNumberOptions();

    options['allowMinus'] = false;
    options['allowPlus'] = false;

    this._updateMasks(aContainer.find('[data-mask=money-positive]'), "currency", options);
  }

  _updatePercentageMasks(aContainer) {
    this._updateMasks(aContainer.find('[data-mask=percentage]'), "percentage", this._getDefaultNumberOptions());
  }

  _updateYearMasks(aContainer) {
    this._updateMasks(aContainer.find('[data-mask=year]'), '9999');
  }

  _updatePhoneMasks(aContainer) {
    this._updateMasks(aContainer.find('[data-mask=phone]'), '(99) 9999[9]-9999');
  }

  _updateMasks(aInputs, aMask, aOptions) {
    Inputmask(aMask, aOptions).mask(aInputs);
  }

  _updateZipcodeMasks(aContainer) {
    this._updateMasks(aContainer.find('[data-mask=zipcode]'), '99999-999');
  }


  _updateElectorDocumentMasks(aContainer) {
    this._updateMasks(aContainer.find('[data-mask=elector]'), '9999 9999 99 99');
  }

  _updateCreditCardMasks(aContainer) {
    let creditContainer = aContainer.find('[data-mask=credit-card]'),
        formSelector = creditContainer.attr('data-form-selector'),
        formElement = $(formSelector)

    if (creditContainer.length === 0 || formElement.length === 0) {
      return
    }

    let card = new Card({
      form: formSelector,
      container: '[data-mask=credit-card]',

      width: 400,

      formSelectors: {
        numberInput: '[data-input=card-number]', // optional — default input[name="number"]
        expiryInput: '[data-input=card-expiry]', // optional — default input[name="expiry"]
        cvcInput: '[data-input=card-verification]', // optional — default input[name="cvc"]
        nameInput: '[data-input=card-name]' // optional - defaults input[name="name"]
      },

      placeholders: {
        name: 'Nome completo',
        expiry: '••/••',
        cvc: '•••'
      },

      messages: {
        validDate: 'VALIDADE', // optional - default 'valid\nthru'
        monthYear: 'mês/ano', // optional - default 'month/year'
      },
    })

    this._updateCardInitialValue(creditContainer, formElement)
  }

  _updateCardInitialValue(aCreditContainer, aFormElement) {
    if (aCreditContainer.attr('data-jp-card-initialized') === 'true') {
      // XXX: não está atualizando o card!
      // aFormElement.find('[data-card-input=true]').trigger('change')
    } else {
      setTimeout(function() {
        _updateCardInitialValue()
      }, 10)
    }
  }

  _updateCustomMasks(aContainer) {
    const elems = aContainer.find('[data-mask=custom]')

    Array.from(elems).forEach((aInput) => {
      const mask = aInput.getAttribute('data-inputmask-mask'),
            options = {}

      this._updateMasks(aInput, mask, options)
    })
  }

  _getDefaultNumberOptions() {
    return {
      alias: 'numeric',
      autoGroup: true,
      digits: 2,
      nullable: true,
      digitsOptional: false,
      prefix: '',
      groupSeparator: '.',
      radixPoint: ',',
      placeholder: ' ',
      showMaskOnHover: false,
      showMaskOnFocus: false,
      clearMaskOnLostFocus: true
    };
  }
}

window._MaskManager = MaskManager

$(function() {
  const maskManager = new MaskManager(),
        container = $(document)

  maskManager.initMasks(container)
})

document.addEventListener('turbo:load', (aEvent) => {
  const maskManager = new MaskManager(),
        container = $(document)

  maskManager.initMasks(container)
})
