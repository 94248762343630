/**
 * Utilitário com helpers de DOM muito usados pelos componentes.
 *
 * [constructor]
 *
 *  - aContainer: elemento em que todos os métodos desse utilitário serão
 *      invocados. Assim, o método find faz uma seleção escopada em
 *      aContainer. O método data, retorna um 'data-attribute' do aContainer.
 *      Isso segue o padrão de componentes da aplicação em que toda informação
 *      relevante deve estar no container máximo do elemento html.
 *
 * [public API]
 *
 *  - find(aSelector): faz uma seleção escopada por aContainer
 *
 *  - data(aSelector, aValue): retorna ou seta o valor de um data-attribute de
 *      aContainer
 *
 * [exemplo de uso]
 *
 * function MeuComponente(aContainerDoMeuComponente) {
 *    ...
 *
 *    var self = this,
 *        containerDoMeuComponente = aContainerDoMeuComponente,
 *        _domHelper = new DomHelper(containerDoMeuComponente)
 *    ...
 *
 *    function _algumFunctionPrivada() {
 *       var elementoLocalAoContainer = _domHelper.find('[data-id=elemento]')
 *       ...
 *    }
 *
 * }
 *
 */

export class DomHelper {

  /**
   * @constructor
   *
   * @param {Element} aContainer Elemento que deve ser o escope deste helper.
   * @return {DomHelper}
   */
  constructor(aContainer) {
    this.container = aContainer
  }

  /* public API */

  /**
   * Retorna uma busca para o selector passado como parâmetro e escopada
   * pelo container.
   *
   * @param {string} aSelector
   * @return {Element}
   */
  find(aSelector) {
    return this._find(aSelector)
  }

  /**
   * Retorna uma busca baseada em data-attributes
   *
   * @param {string} aName Nome da chave usada no atributo data-*
   * @param {string} aValue Valor para match no selector
   * @return {Element}
   */
  findByData(aName, aValue) {
    return this._findByData(aName, aValue)
  }

  /**
   * Retorna ou seta o valor de um data-attribute de aContainer
   *
   * @param {string} aName Nome do data attribute (data-nome)
   * @param {object} aValue Valor ou undefined para apenas recuperar o valor.
   * @return {object}
   */
  data(aName, aValue) {
    return this._data(aName, aValue)
  }

  /**
   * Dispara o evento aEventType tendo o container como target
   *
   * @param {string} aEventType
   * @param {array,object} aEventData
   */
  fireEvent(aEventType, aEventData) {
    return this._fireEvent(aEventType, aEventData)
  }

  /**
   * Dispara o evento aEventType tendo outro elemento como target
   *
   * @param {Element} aEventTarget
   * @param {string} aEventType
   * @param {array,object} aEventData
   */
  fireEventOnElement(aEventTarget, aEventType, aEventData) {
    return this._fireEventOnElement(aEventTarget, aEventType, aEventData)
  }

  /* privates */

  _find(aSelector) {
    // encapsula no try catch para permitir busca com selector inválido
    try {
      return this.container.querySelectorAll(aSelector)
    } catch(aException) {

    }

    return []
  }

  _findByData(aName, aValue) {
    var selector = '[data-' + aName + '=' + aValue + ']'

    return this._find(selector)
  }

  _fireEvent(aEventType, aEventData) {
    this._fireEventOnElement(this.container, aEventType, aEventData)
  }

  _fireEventOnElement(aEventTarget, aEventType, aEventData) {
    const event = new CustomEvent(aEventType, { detail: aEventData })

    aEventTarget.dispatchEvent(event)
  }

  _data(aName, aValue) {
    if (undefined === aValue) {
      return this.container.getAttribute(`data-${aName}`)
    }

    return this.container.setAttribute(`data-${aName}`, aValue)
  }
}
