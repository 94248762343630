/**
 * Componente básico
 */

class BaseComponent {

  /* Private properties */

  _container = null

  constructor(aContainer) {

    if (typeof(aContainer) === 'string') {
      this._container = document.querySelector(aContainer)
    } else {
      this._container = aContainer
    }

    if (! this._container) {
      this._throwContainerNotFoundError(aContainer)
    }
  }

  get container() {
    return this._container
  }

  /* Public API */

  /**
   * Retorna uma busca para o selector passado como parâmetro e escopada
   * pelo container.
   *
   * @param {string} aSelector
   * @return {HTMLElement}
   */
  find(aSelector) {
    return this._find(aSelector)
  }

  /**
   * Retorna uma busca de vários elementos para o selector passado como
   * parâmetro e escopada pelo container.
   *
   * @param {string} aSelector
   * @return {NodeList}
   */
  findAll(aSelector) {
    return this._findAll(aSelector)
  }

  /**
   * Retorna uma busca baseada em data-attributes
   *
   * @param {object} hash com nome: valor
   * ou
   * @param {string} aName Nome da chave usada no atributo data-*
   * @param {string} aValue Valor para match no selector
   * @return {NodeList}
   */
  findByData(...aArgs) {
    return this._findByData(...aArgs)
  }

  /**
   * Retorna uma busca baseada em data-attributes para inputs
   *
   * @param {object} hash com nome: valor
   * ou
   * @param {string} aName Nome da chave usada no atributo data-input
   * @return {NodeList}
   */
  findInput(aInputName) {
    return this._findByData('input', aInputName)
  }


  /**
   * Retorna uma busca baseada em data-attributes para components
   *
   * @param {object} hash com nome: valor
   * ou
   * @param {string} aName Nome da chave usada no atributo data-component
   * @return {NodeList}
   */
  findComponent(aComponentName) {
    return this._findByData('component', aComponentName)
  }

  /**
   * Retorna uma busca de vários elementos baseada em data-attributes
   *
   * @param {object} hash com nome: valor
   * ou
   * @param {string} aName Nome da chave usada no atributo data-*
   * @param {string} aValue Valor para match no selector
   * @return {NodeList}
   */
  findAllByData(...aArgs) {
    return this._findAllByData(...aArgs)
  }

  /**
   * Alias para facilitar event listeners no padrão jQuery.
   *
   * @param aEventType {string} tipo do evento a ser ouvido pelo container do componente.
   * @param {string||function} escope e função a ser invocada pelo event listener.
   * @return {EventListener}
   */
  on(aEventType, ...aArgs) {
    return this._on(aEventType, ...aArgs)
  }

  /**
   * Alias para facilitar event trigger no padrão jQuery.
   *
   * @param aEventType {string} tipo do evento a ser disparando.
   * @param aData {object} dados passos no evento
   * @return {Event}
   */
  fire(aEventType, ...aArgs) {
    return BaseComponent._fire(this.container, aEventType, ...aArgs)
  }

  /**
   * Retorna ou atribui um atributo do container
   *
   * @param aAttributeName {string} nome do atributo
   * @param aAttributeValue {string} valor do atributo se for atribuir
   * @return {String} Valor do atributo
   */
  attr(aAttributeName, aAttributeValue) {
    if (undefined === aAttributeValue) {
      return this.container.getAttribute(aAttributeName);
    }

    return this.container.setAttribute(aAttributeName, aAttributeValue);
  }

  /**
   * Retorna ou atribui um atributo data do container
   *
   * @param aAttributeName {string} nome do atributo data
   * @param aAttributeValue {string} valor do atributo data se for atribuir
   * @return {String} Valor do atributo
   */
  data(aAttributeName, aAttributeValue) {
    return this.attr(`data-${aAttributeName}`, aAttributeValue)
  }

  /**
   * Habilita/desabilita elemento
   *
   * @param aElement {DOMElement} Elemento
   * @param aEnable {boolean}
   */
  enableElement(aElement, aEnable) {
    if (aElement) {

      if (typeof(aEnable) === 'undefined') {
        return this.disableElement(aElement, false)
      } else {
        return this.disableElement(aElement, ! aEnable)
      }
    }
  }

  /**
   * Habilita/desabilita elemento
   *
   * @param aElement {DOMElement} Elemento
   * @param aDisabled {boolean}
   */
  disableElement(aElement, aDisabled) {
    if (aElement) {

      if (typeof(aDisabled) === 'undefined') {
        aElement.setAttribute('disabled', 'disabled')
      } else {
        if (!!(aDisabled)) {
          aElement.setAttribute('disabled', 'disabled')
        } else {
          aElement.removeAttribute('disabled')
        }
      }

      return aElement
    }
  }

  /** Utils */

  chunkArray(aArray, aChunkSize) {
    let result = [],
        array = Array.from(aArray)

    if (aChunkSize < 1) {
      return aArray
    }

    for (var i = 0; i < array.length; i += aChunkSize) {
      result.push(array.slice(i, i + aChunkSize))
    }

    return result
  }

  mergeDeep(...objects) {
    const isObject = obj => obj && typeof obj === 'object';

    return objects.reduce((prev, obj) => {
      Object.keys(obj).forEach(key => {
        const pVal = prev[key];
        const oVal = obj[key];

        if (Array.isArray(pVal) && Array.isArray(oVal)) {
          prev[key] = pVal.concat(...oVal);
        }
        else if (isObject(pVal) && isObject(oVal)) {
          prev[key] = this.mergeDeep(pVal, oVal);
        }
        else {
          prev[key] = oVal;
        }
      });

      return prev;
    }, {})
  }

  /* Privates */

  /** Selectors */

  _find(aSelector) {
    return (this.container && this.container.querySelector(aSelector))
  }

  _findAll(aSelector) {
    return (this.container && this.container.querySelectorAll(aSelector))
  }

  _findByDataSelector(...aArgs) {
    let selector = null

    if (aArgs.length === 2) {
      selector = `[data-${aArgs[0]}="${aArgs[1]}"]`
    } else {
      let object = aArgs[0],
          name = Object.keys(object)[0],
          value = object[name]

      selector = `[data-${name}=${value}]`
    }

    return selector
  }

  _findByData(...aArgs) {
    return this._find(this._findByDataSelector(...aArgs))
  }

  _findAllByData(...aArgs) {
    return this._findAll(this._findByDataSelector(...aArgs))
  }

  /** Events */

  _on(aEventType, ...aArgs) {
    let eventType = aEventType,
        scope = (typeof aArgs[0] === 'function') ? null : aArgs[0],
        handler = (typeof aArgs[0] === 'function') ? aArgs[0] : aArgs[1],
        self = this

    return this.container.addEventListener(eventType, function() {
      let event = arguments[0]

      if ((! event.cancelBubble) && (scope === null || event.target.matches(scope) || event.currentTarget.matches(scope))) {
        handler.apply(self, arguments)
      }
    })
  }

  static _fire(aComponent, aEventType, aData) {
    let event = new CustomEvent(aEventType, {
        bubbles: true,
        cancelable: true,
        detail: aData
      })

    aComponent.dispatchEvent(event)

    return event
  }

  /** Helpers */

  _isArrayEqual(a, b) {
    return (a === null && b === null) ||
           (a !== null && b !== null) &&
           ( a.length === b.length && (a.every( e => b.includes(e) )))
  }

  /** Styles */

  _showElement(aElement) {
    if (aElement) {
      aElement.classList.remove('d-none')
    }
  }

  _hideElement(aElement) {
    if (aElement) {
      aElement.classList.add('d-none')
    }
  }

  _toggleElementVisibility(aElement, aVisible) {
    if (aVisible) {
      this._showElement(aElement)
    } else {
      this._hideElement(aElement)
    }
  }

  /** Errors */

  _throwContainerNotFoundError(aContainer) {
    throw new Error(`Container não encontrado: ${aContainer}.`)
  }
}

export { BaseComponent }
