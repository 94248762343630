/**
 * Componente responsável por controlar requisições ajax para a index de algum
 * recurso.
 *
 * A estrutura básica de um remote-content, deve ser:
 *
 *  .qualquer-coisa{'data-remote-content': 'identificador-de-seu-remote'}
 *
 *    ... algum remote form com filtros ou link, por exemplo. ambos devem ser
 *    do tipo 'remote'..
 *
 *    .outra-classe{'data-remote-content-result': 'identificador-de-seu-remote'}
 *
 *      = render 'index' # as requisições remotas só irão renderizar o conteúdo
 *                       # da partial _index. se sua view inicial não carrega
 *                       # nada, esse render é desnecessário e assim que o
 *                       # 'index' é carregado, o remote-content é invocado para
 *                       # exibir o conteúdo.
 *
 */

import { DomHelper } from '../utils/dom-helper'
import { UrlHelper } from '../utils/url-helper'

export class RemoteContent {

  constructor(aRemoteContentContainer, aFilterBar) {

    let self = this

    this.remoteContentContainer = aRemoteContentContainer
    this.domHelper = new DomHelper(this.remoteContentContainer)
    this.urlHelper = new UrlHelper()
    this.remoteContentId = this.remoteContentContainer.getAttribute('data-remote-content'),
    this.domResult = aRemoteContentContainer.querySelector(`[data-remote-content-result=${this.remoteContentId}]`)
    this.filterBar = aFilterBar
    this.remoteContentContainer._remoteContent = this

    /* event handlers */

    this._setupEventListeners()

    /* setup */

    if (this.remoteContentContainer.getAttribute('data-ignore-initial-load') !== 'true') {
      self.loadData()
    }
  }

  /* privates */

  /** event listeners */
  _setupEventListeners() {
    // o index será recarregado...

    this.remoteContentContainer.addEventListener('ajax:before', (aEvent) => {
      this._onAjaxBefore(aEvent)
    })

    this.remoteContentContainer.addEventListener('ajax:success', (aEvent) => {
      this._onAjaxSuccess(aEvent)
    })

    this.remoteContentContainer.addEventListener('ajax:error', (aEvent) => {
      this._onAjaxError(aEvent)
    })

    // usuário clicou no cabeçalho de ordenação de uma tabela dentro do remote
    // content

    delegate(this.remoteContentContainer, 'th a[data-remote=true]', 'click', (aEvent) => {
      this.updateUrlAndFilterBarForm(aEvent.delegateTarget.getAttribute('href'))
    })

    // usuário clicou numa página de navegação para remote content

    delegate(this.remoteContentContainer, '.pagination a[data-remote=true]', 'click', (aEvent) => {
      this.updateUrlAndFilterBarForm(aEvent.delegateTarget.getAttribute('href'))
    })

    // usuário clicou em limpar filtro e um remote-content com filterbar...

    delegate(this.remoteContentContainer, '[data-input=clear-filter]', 'click', (aEvent) => {
      if (this.filterBar !== undefined) {
        aEvent.preventDefault()
        aEvent.stopPropagation()

        this.filterBar.clearFilter()
      }
    })
  }

  _onAjaxBefore(aEvent) {
    this.domHelper.fireEvent('remote-content:before')

    this.startLoading()
  }

  _onAjaxSuccess(aEvent) {
    if (aEvent.target.getAttribute('data-remote-content') === 'ignore') {
      // temos que ignorar itens marcados para que possa ter ações remotas
      // dentro de um remote-content, como no caso dos favoritos.

      return
    }

    const data = aEvent.detail[2].response

    this.showResults(data)

    this.domHelper.fireEvent('remote-content:after')

    if (this.afterContentCallback) {
      this.afterContentCallback()
    }
  }

  _onAjaxError(aEvent) {
    // Caso seja não autorizado, redirectionamos a página
    this.showError(aEvent.detail[2] /* aXhr */, aEvent.detail[0] /* aError */)
  }

  /** loading */

  startLoading() {
    const partial = this.remoteContentContainer.querySelector('[data-remote-content-partial]')

    this.remoteContentContainer.setAttribute('data-loading', true)
    this.remoteContentContainer.setAttribute('data-error', false)

    if (partial) {
      partial.setAttribute('data-loading', true)
      partial.setAttribute('data-error', false)
    }
  }

  stopLoading() {
    const partial = this.remoteContentContainer.querySelector('[data-remote-content-partial]')

    this.remoteContentContainer.setAttribute('data-loading', false)

    if (partial) {
      partial.setAttribute('data-loading', false)
    }
  }

  /** results */

  showResults(aData) {

    /* Precisamos extrair as 'partials' do resultado para que possam ser
     * inseridas em outra parte do DOM. Isso permite que o mesmo resultado
     * de uma requisição traga blocos de DOM (como totalizações, listas e etc.)
     * que podem ser inseridas de modo independente no DOM, sem necessariamente
     * ser no _domResult. Tudo que não estiver como 'partial' será inserido
     * normalmente no _domResult.
     */

    let data = aData,
        wrapper = document.createElement('div')

    wrapper.innerHTML = data

    let partials = wrapper.querySelectorAll('[data-remote-content-partial-target]')

    Array.from(partials).forEach((aItem) => {
      let partial = aItem,
          targetId = partial.getAttribute('data-remote-content-partial-target'),
          target = document.querySelector('[data-remote-content-partial="' + targetId + '"]')

      target.innerHTML = partial.outerHTML

      partial.remove()
    })

    this.domResult.innerHTML = wrapper.innerHTML
    this.enableSubmit()
    this.stopLoading()
  }

  showError(aXhr, aError) {
    // Caso seja não autorizado, redirectionamos para o path retornado.

    if (aXhr.status === 401) {
      var path = aXhr.responseJSON['redirect_to']
      window.location = path
      return
    }

    if (aXhr.status === 500) {
      this.remoteContentContainer.setAttribute('data-error', true)
    }

    this.stopLoading()
  }

  loadData() {
    let domRemoteContentForm = this.remoteContentContainer.querySelector('form')

    if (domRemoteContentForm.getAttribute('data-remote') === 'true') {
      Rails.fire(domRemoteContentForm, 'submit')
    } else {
      //XXX: pre-commit não permite console.lo(g)!
      // console.lo('Form precisa ser remoto. Adicione o data-remote: true.')
    }
  }

  updateUrlAndFilterBarForm(aUrl) {
    var params = this.urlHelper.getUrlParams(aUrl)

    this.urlHelper.updateUrlRawParams(
      params,
      true, /* aPushState (false para não adicionar nova entrada no histórico) */
      { reload: true } /* sinaliza um reload para o popstate para backbutton funcionar */
    )

    this.updateFilterBarForm(params)
  }

  updateFilterBarForm(aParams) {
    // temos que atualizar todos os campos marcados com 'data-remote-content-param'
    // para haver sincronia dos diversos parametros (paginacao, sort, etc...)

    var inputs = this.remoteContentContainer.querySelectorAll('[data-remote-content-param]')

    Array.from(inputs).forEach((aInput) => {
      var input = aInput,
          inputId = input.getAttribute('id'),
          paramValue = aParams[inputId]

        input.value = (typeof paramValue === 'undefined' ? '' : paramValue)
    })
  }

  /* Reabilita os botões de submits que possam ter disable-with.
   */
  enableSubmit() {
    var inputs = this.domHelper.find('input[type=submit]:disabled')

    Array.from(inputs).forEach((aInput) => { aInput.disabled = false })
  }

  /*
   * Recarrega dados (com seus filtros e parâmetros de page, sort, etc.) de
   * uma entreada no histórico.
   */
  popRemoteStateAndLoadData(aEvent) {
    // aEvent.target.location.search = parametros da url antiga que está sendo 'popped'
    // ex: ?cod_gestora=+&data_assinatura=&data_publicacao_portal=&decricao_modalidade=+&page=17&search=&sort_column=integration_supports_creditors.nome&sort_direction=desc&status=+&tipo_objeto=+
    var params = this.urlHelper.getUrlParams(aEvent.target.location.search)

    this.updateFilterBarForm(params)

    this.loadData()
  }

}
