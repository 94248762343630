require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

// Usar a função import() separa os assets em chuncks assíncronos
import('popper.js')
import('bootstrap')

//XXX: REMOVER SE ASSETS NÃO QUEBRAREM
import('components')
import('controllers')
import './modules/index'

import '../stylesheets/base.scss'

// importante para conectar com turbo-ios
import * as Turbo from "@hotwired/turbo"
window.Turbo = Turbo

import delegate from 'delegate-it'
window.delegate = delegate

import Rails from '@rails/ujs'
window.Rails = Rails

import { Tab } from 'bootstrap'
window.Tab = Tab

import $ from 'jquery'
window.$ = $

import select2 from 'select2'
window.select2 = select2

/*
 * Para o backbutton funcionar nas ferramentas que usam pushState
 */

window.addEventListener('popstate', (aEvent) => {
  const stateData = aEvent.state,
        reload = (stateData && stateData['reload'])

  if (reload) {
    window.location.reload()
  }
}, true)
